<template>
  <div class="pa-3 bg-default">
    <section class="banner indigo pa-2 rounded d-flex">
      <img src="../../assets/img/broadchat.svg" height="100px" />
      <div>
        <h1 class="white--text ml-3">Broadcast</h1>
      </div>
    </section>
    <v-row>
      <v-col cols="12" md="2" class="pb-0 mb-0">
        <label for="event">Pilih kelompok</label>
      </v-col>
      <v-col cols="12" md="10" class="pb-0 mb-5" v-if="allPelatihan">
        <v-select
          :options="allPelatihan.data"
          :reduce="(nama) => nama.id"
          v-model="select_pelatihan"
          @search="(query) => (find = query)"
          label="nama"
          class="white"
          outlined
          @input="getGroup(select_pelatihan)"
        ></v-select>
      </v-col>
    </v-row>
    <div>
      <v-card>
        <v-progress-linear
          :active="loadTable"
          :indeterminate="loadTable"
          absolute
          top
          color="orange"
        ></v-progress-linear>
        <section class="pa-3">
          <h3 v-if="!grupByPelatihan.length">
            Pilih pelatihan terlehbih dahulu
          </h3>
          <h3 v-if="grupByPelatihan.length">
            Pilih Group yang akan di broadcast
          </h3>
        </section>
        <v-container v-if="grupByPelatihan.length">
          <v-checkbox
            v-for="item in grupByPelatihan"
            :key="item.id"
            v-model="selectedGroup"
            :label="item.nama"
            :value="item.id"
            class="ma-0"
            hide-details="auto"
          ></v-checkbox>
        </v-container>

        <section v-if="selectedGroup.length" class="pa-3">
          <v-textarea
            solo
            label="Ketik pesan..."
            hint="mengetik..."
            v-model="msg"
          ></v-textarea>
          <v-btn dark color="indigo" @click="letsBroadcast">Kirim</v-btn>
        </section>
      </v-card>
    </div>
  </div>
</template>

<script>
import { GET_EVENT } from "../../graphql/Event";
import { GROUP_BY_EVENT } from "../../graphql/Group";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";

export default {
  apollo: {
    allPelatihan: {
      query: GET_EVENT,
      variables() {
        return { page: 1, first: 10, search: this.find };
      },
      error(err) {
        console.log(err);
      },
    },
    grupByPelatihan: {
      query: GROUP_BY_EVENT,
      variables() {
        return { pelatihan_id: this.idEvent };
      },
      skip() {
        return !this.idEvent;
      },
      error(err) {
        console.log(err);
      },
    },
  },
  name: "broadcast",
  data() {
    return {
      allPelatihan: [],
      find: "",
      select_pelatihan: null,
      idEvent: null,
      grupByPelatihan: [],
      selectedGroup: [],
      isNext: false,
      msg: "",
    };
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.grupByPelatihan.loading;
    },
  },
  components: {
    vSelect,
  },
  methods: {
    getGroup(id) {
      this.idEvent = id;
      this.$apollo.queries.grupByPelatihan.refetch();
      this.selectedGroup = [];
    },
    letsBroadcast() {
      let arr = this.selectedGroup.map((el) => {
        return parseInt(el);
      });
      let data = {
        isi: this.msg,
        penerima: arr,
      };
      this.$store.dispatch("broadcast/createBroadcast", data).then((data) => {
        console.log(data);
        this.msg = "";
        this.selectedGroup = [];
        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          timer: 5000,
          position: "top",
          toast: true,
        });
      });
    },
  },
};
</script>

<style scoped>
.bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>